.btn-attendances-update {
    padding-top: 40px;
    display: flex;
    
  }

.btn-margin {
    margin:20px 100px 20px 2000px;
    margin-left:auto;
    margin-right: 40%;
}

.goalline{
    border-top: black;
}