.test1{
    position: absolute;
    margin-left: 240px;
    margin-top: 350px;
}

.test2{
    position: absolute;
    margin-left: 240px;
    margin-top: 190px;
}
.test3{
    position: absolute;
    margin-left: 400px;
    margin-top: 300px;
}
.test4{
    position: absolute;
    margin-left: 380px;
    margin-top: 60px;
}
.test5{
    position: absolute;
    margin-left: 60px;
    margin-top: 60px;
}
.test6{
    position: absolute;
    margin-left: 100px;
    margin-top: 300px;
}

.picture{
    position: absolute;
    margin-top:50px;
    left: 40px;
}